<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.cashRequirement') }}</h5>

                <div class="p-field p-grid">
                    <div class="p-col-6 p-md-3">
                        <span class="p-input-icon-right">
                            <InputText type="text" :placeholder="$t('system.shop')" v-model="searchTerm" @input="debounceSearch" />
                            <i class="pi pi-search" />
                        </span>
                    </div>
                    <div v-show="showReport" class="p-col-12 p-md-9 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="showDownloadingData" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                </div>
                <div class="p-grid" v-if="showReportData">
                    <div class="p-col-6 p-md-4">
                        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div class="p-d-flex p-ai-center muted-text">
                                <h6 class="p-m-0">{{ $t('reports.pendingPayments') }}</h6>
                            </div>
                            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div class="p-d-flex p-flex-column">
                                    <span class="p-mb-1 fs-xlarge">{{ formatCurrency(totalRow.PendingPayments) + ' ' + $t('general.currency') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-4">
                        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div class="p-d-flex p-ai-center muted-text">
                                <h6 class="p-m-0">{{ $t('reports.requiredAmount') }}</h6>
                            </div>
                            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div class="p-d-flex p-flex-column">
                                    <span class="p-mb-1 fs-xlarge">{{ formatCurrency(totalRow.RequiredAmount) + ' ' + $t('general.currency') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-4">
                        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div class="p-d-flex p-ai-center muted-text">
                                <h6 class="p-m-0">{{ $t('reports.availableAmount') }}</h6>
                            </div>
                            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div class="p-d-flex p-flex-column">
                                    <span class="p-mb-1 fs-xlarge">{{ formatCurrency(totalRow.AvailableAmount) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <DataTable @page="onPage($event)" :paginator="true" sortField="RequiredAmount" :sortOrder="-1" :rows="perPage" :loading="!showReportData" :value="filteredData" ref="datatable" responsiveLayout="scroll">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column field="ShopCode" :header="$t('system.code')" :sortable="true"></Column>
                        <Column field="ShopName" :header="$t('system.shop')" :sortable="true"></Column>
                        <Column field="Limit" :header="$t('reports.limit')" :sortable="true">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.Limit) }} </template></Column
                        >
                        <Column field="Sold" :header="$t('reports.sold')" :sortable="true">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.Sold) }} </template></Column
                        >
                        <Column field="PendingPayments" :header="$t('reports.pendingPayments')" :sortable="true">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.PendingPayments) }} </template></Column
                        >
                        <Column field="RequiredAmount" :header="$t('reports.requiredAmount')" :sortable="true">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.RequiredAmount <= 0">{{ formatCurrency(slotProps.data.RequiredAmount) }} </span>
                                <span style="color: red" v-else>{{ formatCurrency(slotProps.data.RequiredAmount) }} </span>
                            </template></Column
                        >
                        <Column field="AvailableAmount" :header="$t('reports.availableAmount')" :sortable="true">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.AvailableAmount) }} </template></Column
                        >
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
import { uiSettings } from '../../../settings/uiSettings';
export default {
    name: 'report',
    data() {
        return {
            reportData: [],
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            showReportData: false,
            showDownloadingData: false,
            startDateModel: null,
            errorKeyCount: 0,
            showError: false,
            term: '',
            debounce: null,
            searchTerm: '',
            debounceTime: 0,
            perPage: uiSettings.tableRows,
            crtPage: 1,
            totalRow: {},
        };
    },
    beforeMount() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day;
        this.startDateModel = this.formatEnDate(new Date(startDateString));
        this.showReport();
        this.debounceTime = this.getSearchDebounceSeconds();
    },
    computed: {
        filteredData() {
            if (!this.term || this.term.length < 1) {
                return this.reportData;
            }
            return this.reportData.filter((item) => {
                return item.ShopName.toLowerCase().indexOf(this.term.toLowerCase()) >= 0;
            });
        },
    },
    methods: {
        showReport() {
            this.showError = false;
            this.BoReportsApi.getCashRequirement(this.startDateModel)
                .then((response) => {
                    this.reportData = response.data.Items;
                    this.totalRow = response.data.Total;
                    this.showReportData = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                });
        },
        saveXls() {
            this.showError = false;
            this.showDownloadingData = true;
            this.BoSaveReportsApi.saveCashRequirement(this.startDateModel)
                .then((response) => {
                    this.showDownloadingData = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'CashRequirementr_' + this.formatEnDate(this.startDateModel) + '.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.showDownloadingData = false;
                });
        },
        debounceSearch(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.term = event.target.value;
            }, this.debounceTime);
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
    },
};
</script>

